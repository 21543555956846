import { combineReducers } from 'redux';
import BCDetails from './BCDetails';
import wallet from './wallet';
import authorization from './authorization';

export default combineReducers({
    wallet,
    bc: BCDetails,
    authorization,
});
