import { combineReducers } from 'redux';
import {
    AUTHZ_CODE_SET,
    VERIFY_ACCOUNT_ERROR,
    VERIFY_ACCOUNT_IN_PROGRESS,
    VERIFY_ACCOUNT_SUCCESS,
} from '../../constants/authorization';

const authzCode = (state = '', action) => {
    switch (action.type) {
    case AUTHZ_CODE_SET:
        return action.value;
    default:
        return state;
    }
};

const connection = (state = {
    inProgress: false,
}, action) => {
    switch (action.type) {
    case VERIFY_ACCOUNT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case VERIFY_ACCOUNT_SUCCESS:
    case VERIFY_ACCOUNT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    authzCode,
    connection,
});
